<template>
  <div class="max-w-7xl m-auto mt-12">
    <div class="flex justify-between mb-4">
      <div class="flex justify-normal mb-4">
        <div style="position:relative;">
          <input type="text" placeholder="Cerca..." v-model="search" class="border p-2" @keyup.enter="SearchFilter();"
            style="min-width:300px;">
          <div class="resetfilter" @click="ResetFilter();" :class="{ 'visible': (search) }"><span>x</span></div><button
            @click="SearchFilter();" class="bg-blue-500 text-white p-2">Cerca</button>
        </div><button class="bg-slate-500 text-white p-2 deleteConsumer"
          :class="{ 'enabled': (selectedConsumerIds.length) }" @click="deleteSelectedConsumers">Cancella
          Selezionati</button>


        <div>
          <!-- <date-picker class="p-2" v-model:value="rangeDate" range format="DD-MM-YYYY" value-type="date"></date-picker>
        <button @click="DateFilter();" class="bg-blue-500 text-white p-2">Filtra</button> -->
        </div>
      </div>
      <div class="flex justify-normal mb-4">
        <button class="download-data bg-slate-500 text-white p-2" :class="{ 'enabled': (selectedConsumerIds.length) }"
          @click="exportToXLS">Scarica dati selezionati </button>
        <button class="bg-blue-500 text-white p-2" @click="exportToXLS">Scarica tutti i dati</button>
      </div>
    </div>
    <table class="w-full text-left border-collapse">

      <thead>
        <tr>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
            <div><input type="checkbox" @change="toggleAllConsumers"
                :checked="selectedConsumerIds.length === consumers.length"></div>
          </th>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
            Azienda
          </th>

          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
            Partita IVA
          </th>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
            Attività
          </th>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
            Email
          </th>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
            Telefono
          </th>

          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
            Data
          </th>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          </th>
        </tr>
      </thead>


      <tbody>
        <tr v-for="consumer in consumers" :key="consumer.ID" :class="{ 'detail': (openDetailId === consumer.ID) }">
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <p class="text-gray-900 whitespace-no-wrap">
              <input type="checkbox" :value="consumer.ID" v-model="selectedConsumerIds">
            </p>
          </td>

          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <p class="text-gray-900 whitespace-no-wrap">{{ consumer.company_name }}</p>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <p class="text-gray-900 whitespace-no-wrap">{{ consumer.vatcode }}</p>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <p class="text-gray-900 whitespace-no-wrap">{{ consumer.company_type }}</p>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <p class="text-gray-900 whitespace-no-wrap">{{ consumer.email }}</p>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <p class="text-gray-900 whitespace-no-wrap">{{ consumer.phone }}</p>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <p class="text-gray-900 whitespace-no-wrap">{{ consumer.date_add }}</p>
          </td>
          <td @click="toggleDetails(consumer.ID)"
            class="px-5 py-5 border-b border-gray-200 bg-white text-sm cursor-pointer">
            <p class="text-gray-900 whitespace-no-wrap">Dettagli</p>
          </td>
          <div class="sub-table" v-if="openDetailId === consumer.ID">
            <td class="px-5 py-5 border-b border-gray-200" style="min-width:60px;">
            </td>
            <td class="px-5 py-5 border-b border-gray-200 text-sm reference">
              <span class="text-white whitespace-no-wrap">Referente</span>
              <p class="text-white whitespace-no-wrap">{{ consumer.agent_name }}</p>
            </td>
            <td class="px-5 py-5 border-b border-gray-200 text-sm address">
              <span class="text-white whitespace-no-wrap">Luogo</span>
              <p class="text-white whitespace-no-wrap">{{ consumer.address }}, {{ consumer.city }}, {{ consumer.province
              }}</p>
            </td>

            <td class="px-5 py-5 border-b text-sm vatcode">
              <span class="text-white whitespace-no-wrap">Partita IVA</span>
              <p class="text-white whitespace-no-wrap">{{ consumer.vatcode }}</p>
            </td>

            <td class="px-5 py-5 border-b text-sm phonetd">
              <span class="text-white whitespace-no-wrap">Telefono</span>
              <p class="text-white whitespace-no-wrap">{{ consumer.phone }}</p>
            </td>
            <td class="px-5 py-5 border-b text-sm notes">
              <span class="text-white whitespace-no-wrap">Note</span>
              <div class="text-white whitespace-no-wrap note-block">{{ consumer.notes }}</div>
            </td>
          </div>
        </tr>
      </tbody>

    </table>

    <div class="flex justify-between items-center p-4">
      <span>Pagina {{ realCurrentPage }} di {{ pageTotal }} | Totale iscrizioni: {{ totalConsumers }}</span>
      <div>
        <button class="bg-blue-500 text-white p-2" @click="prevPage">Indietro</button>
        <button class="bg-blue-500 text-white p-2" @click="nextPage">Avanti</button>
      </div>

    </div>

    <a href="https://www.idexaweb.com/" target="_blank">
      <div class="mx-auto max-w-2xl px-6 py-6 ">
        <div class="credits-container">
          <div class="credits-row">
            <span> Credits</span>
            <img class="idexa-logo" alt="PTS logo" src="../assets/logo-idexaweb.webp">
          </div>
        </div>
      </div>
    </a>

  </div>
</template>
  
<script>
//import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import * as XLSX from 'xlsx';

import Notify from 'simple-notify'
import 'simple-notify/dist/simple-notify.css'

export default {
  components: {
    // DatePicker
  },
  data() {
    return {
      consumers: [],
      selectedConsumerIds: [],
      search: "",
      currentPage: 1,
      realCurrentPage: 0,
      pageTotal: 0,
      perPage: '10',
      dateFrom: '',
      dateTo: '',
      totalConsumers: 0,
      rangeDate: '',
      openDetailId: null,
    };
  },
  methods: {

    toast_ok(task, message) {
      new Notify({
        status: 'success',
        title: task,
        text: message,
        effect: 'slide',
        speed: 300,
        customClass: '',
        customIcon: '',
        showIcon: true,
        showCloseButton: true,
        autoclose: true,
        autotimeout: 3000,
        gap: 20,
        distance: 20,
        type: 1,
        position: 'right top'
      })
    },
    toast_error(task) {
      new Notify({
        status: 'error',
        title: task,
        text: "L'azione ha generato una risposta inaspettata",
        effect: 'slide',
        speed: 300,
        customClass: '',
        customIcon: '',
        showIcon: true,
        showCloseButton: true,
        autoclose: true,
        autotimeout: 3000,
        gap: 20,
        distance: 20,
        type: 1,
        position: 'right top'
      })
    },
    async fetchConsumers() {
      try {
        const response = await fetch(`https://b2b.ptson.com/api/index.php/wp-json/iarf/v1/get_consumers?s=${this.search}&df=${this.dateFrom}&dt=${this.dateTo}&p=${this.currentPage}`);
        if (response.ok) {

          const data = await response.json(); // Estrai i dati come JSON
          this.consumers = data.consumers; // Assegna i dati all'array Consumers
          if (this.totalConsumers == 0) {
            this.totalConsumers = data.totalContacts;
          }
          console.log(data);
          this.realCurrentPage = data.page;
          this.pageTotal = data.pageTotal;
        }
      } catch (error) {
        console.error('Errore di connessione:', error);
      }

    },
    SearchFilter() {
      this.fetchConsumers();
    },
    ResetFilter() {
      this.search = '';
      this.fetchConsumers();
    },
    DateFilter() {
      if (this.rangeDate) {
        this.dateFrom = this.rangeDate[0];
        this.dateTo = this.rangeDate[1];
        console.log(this.dateTo)
        console.log(this.rangeDate)
        this.fetchConsumers();
      }
    },

    toggleAllConsumers() {
      if (this.selectedConsumerIds.length === this.consumers.length) {
        // Se tutti sono già selezionati, deselezionali
        this.selectedConsumerIds = [];
      } else {
        // Altrimenti, seleziona tutti gli ID nella pagina corrente
        this.selectedConsumerIds = this.consumers.map(consumer => consumer.ID);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchConsumers();
      }
    },
    nextPage() {
      if (this.currentPage != this.pageTotal) {
        this.currentPage++;
        this.fetchConsumers();
      }
    },
    async exportToXLS() {
      try {
        let dataToExport = [];

        // Controlla se ci sono elementi selezionati
        if (this.selectedConsumerIds.length > 0) {
          // Filtra i consumatori selezionati
          dataToExport = this.consumers.filter(consumer =>
            this.selectedConsumerIds.includes(consumer.ID)
          );
        } else {
          // Altrimenti, utilizza tutti i consumatori
          dataToExport = this.consumers;
        }

        // Converti i dati in formato per xlsx
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "SelectedConsumers");

        // Scrivi il file XLS
        XLSX.writeFile(workbook, 'SelectedConsumers.xlsx');
      } catch (error) {
        console.error('Errore nell\'esportazione:', error);
      }
    },
    toggleDetails(id) {
      this.openDetailId = this.openDetailId === id ? null : id;
    },

    async deleteSelectedConsumers() {
      console.log(this.selectedConsumerIds)

      await fetch('https://b2b.ptson.com/api/index.php/wp-json/iarf/v1/delete_consumers/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ consumerIds: this.selectedConsumerIds })
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          if (data.success) {
            this.currentPage = 1;
            this.fetchConsumers();
            this.toast_ok('Successo', 'Le risorse selezionate sono state eliminate');
          }

        })
        .catch(error => {
          console.error('Errore nella cancellazione:', error);
          console.error('Errore:', error);

        })

    },

  },

  mounted() {
    this.fetchConsumers(); // Carica i dati all'avvio
  }
};
</script>
  