import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import Dashboard from '@/views/DashBoard.vue';
import Login from '@/views/UserLogin.vue';


// Crea l'istanza del router con la nuova sintassi Vue 3
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Compila il form - PTS',
      component: HomeView
      
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard, 
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login, 
    },
    // Aggiungi qui altre routes se necessario
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isAuthenticated()) {
    next({ name: 'Login' }); // o la tua pagina di login
  } else {
    next();
  }
});

function isAuthenticated() {
  const token = localStorage.getItem('jwt');
  return token && token.length === 221;
}

export default router;