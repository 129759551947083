<template>
  <div  v-if="isLoggedIn" class="bg-gradient-to-br from-sky-50 to-sky-50 min-h-screen">
    <Header />
     <ConsumerList/>
 
  </div>

  <div v-else>
  <UserLogin />      <!-- Mostra un messaggio o reindirizza l'utente alla pagina di login -->
      Non sei autenticato.
  </div>
</template>

<script>
import Header from '../components/DBHeader.vue';

import ConsumerList from '@/components/ConsumerList.vue';


export default {
  components: {
    Header, ConsumerList
  },
  data() {
    return {
      isLoggedIn: false,
    };
  },   created() {
    this.isLoggedIn = this.checkAuthentication();
  },
  methods: {
    checkAuthentication() {
    const token = localStorage.getItem('jwt');
    return token && token.length === 221;
  }
  }
};
</script>
