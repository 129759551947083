<template>
     <div class="w-full min-h-screen bg-gradient-to-br from-sky-50 to-sky-50 p-0 sm:p-12">
        <div class="mx-auto max-w-2xl min-h-96 px-6 py-12 bg-white border-0 shadow-lg sm:rounded-3xl">
            <h3 class="text-2xl font-bold text-center">Login</h3>
            <form @submit.prevent="login" class="max-w-lg m-auto">
                <div class="mt-4">
                    <div>
                        <label class="block" for="email">Email</label>
                        <input type="email" placeholder="Email" id="email" v-model="email"
                            class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600">
                    </div>
                    <div class="mt-4">
                        <label class="block">Password</label>
                        <input type="password" placeholder="Password" id="password" v-model="password"
                            class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600">
                    </div>
                    <div class="flex items-baseline justify-between">
                        <button class="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">Login</button>
                    </div>
                </div>
            </form>
        </div>
        
        <a href="https://www.idexaweb.com/">
        <div class="mx-auto max-w-2xl px-6 py-6">
            <div class="credits-container">
            <div class="credits-row">
                <span> Credits</span>
                <img class="idexa-logo" alt="PTS logo" src="../assets/logo-idexaweb.webp"></div>
            </div>   </div></a>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            email: '',
            password: '',
        };
    },
    methods: {
        generateToken() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
        var token = ''; 
      for (let i = 0; i < 221; i++) {
        token += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
    return token}, 
        async login() {
           // const token = btoa(`${this.email}:${this.password}`);

            try {
                 // const response = await fetch('https://b2b.ptson.com/api/index.php/wp-json/wp/v2/users/me', {
                 //     method: 'GET',
                 //     headers: {
                 //         'Authorization': `Basic ${token}`,
                 //     },
                 // });

                 // if (!response.ok) {
                 //     throw new Error('Credenziali non valide o errore di login');
                 // }
                 if (this.email === 'luana.delmanzo@venditapeluche.com' && this.password === 'L2A7 XaVq laj7 0ddW kFQA aTua') {
               // console.log('Login successful:', user);
                const tokenX = this.generateToken(); 

                // Qui puoi salvare il token per le future richieste autenticate
                localStorage.setItem('jwt', tokenX);

                // Reindirizza l'utente a una pagina protetta, come il dashboard
                this.$router.push('/dashboard');
                 } else { console.error('Login failed');}
            } catch (error) {
                console.error('Login failed:', error);
                // Gestisci qui gli errori di login (ad esempio, mostrando un messaggio all'utente)
            }
        },

    },
};
</script>
  