<template>
    <ConsumerForm /> 
  </template>
  
<script>
  import ConsumerForm from '../components/ConsumerForm.vue';  
  
  export default {
    name: 'HomeView',
    components: {
      ConsumerForm
    }
  }
  </script>
  

  