<template>
    <div class="w-full min-h-screen bg-gradient-to-br from-sky-50 to-sky-50 p-0 sm:p-12">
        <div class="mx-auto max-w-2xl px-6 py-12 bg-white border-0 shadow-lg sm:rounded-3xl">
        <div class="selector-container">        
            <div @click="toggleLanguage" class="langSelector">
    <img :src="'https://hatscripts.github.io/circle-flags/flags/' + (currLang === 'it' ? 'gb' : 'it') + '.svg'" width="24">
    {{ currLang === 'it' ? 'ENGLISH' : 'ITALIANO' }}
</div></div> 

            <div class="flex flex-col flex-wrap mx-auto items-center px-6">
                <img class="main-logo mb-6" alt="PTS logo" src="../assets/logo.webp">
                <h1 class="mb-6 font-semibold text-4xl lg:text-5xl">  {{ currLang === 'it' ? 'Benvenuto su PTSON!' : 'Welcome to PTSON!' }}</h1>
                <h3 class="mb-6 font-medium text-1xl lg:text-2xl" v-if="!formSuccess">
                {{ currLang === 'it' ? 'Compila il form con i tuoi dati aziendali per ricevere le nostre offerte ed essere ricontattato.'
                :'Fill out the form with your business details to receive our offers and to be contacted.'}}</h3>
                <h5 class="mb-6 font-normal text-1xl">{{ currLang === 'it' ? 'Grazie!': 'Thank you'}}</h5>
            </div>

            <!-- Blocco di conferma -->
            <div v-if="formSuccess" class="alert-success">
                {{ currLang === 'it' ? 'I dati sono stati registrati con successo.': 'Your registration is complete. Thank You'}}
            </div>

            <!-- Blocco di errore -->
            <div v-if="formError" class="alert-error">
                Errore: {{ errorMessage }}
            </div>
            <form id="form" @submit.prevent="submitForm" v-if="!formSuccess">

                <div class="flex flex-wrap mx-2 px md:my-6">

                    <div class="w-full md:w-1/2 my-2 md:my-0 relative">
                        <input type="text" v-model="CompanyName"
                            :class="{ 'input-form w-95': true, 'unempty': CompanyName }" required />
                        <span class="label-form">{{ currLang === 'it' ? 'Nome Azienda': 'Company Name'}} *</span>
                    </div>

                    <div class="w-full md:w-1/2 my-2  md:my-0 relative">

                        <select type="text" v-model="CompanyType"
                            :class="{ 'input-form w-95': true, 'unempty': CompanyType }">
                            <option value="" selected="">{{ currLang === 'it' ? 'Tipologia Azienda': 'Company Type'}} *</option>
                            <option value="Ingrosso">{{ currLang === 'it' ? 'Ingrosso': 'Wholesale'}}</option>
                            <option value="Dettaglio">{{ currLang === 'it' ? 'Negozio': 'Retail'}}</option>
                            <option value="eCommerce">eCommerce</option>
                            <option value="Spettacolo Viaggiante">{{ currLang === 'it' ? 'Spettacolo Viaggiante': 'Traveling show'}}</option>
                            <option value="Sala Giochi/Bowling">{{ currLang === 'it' ? 'Sala Giochi/Bowling': 'Game hall/Bowling'}}</option>
                            <option value="Altro">{{ currLang === 'it' ? 'Altro (Specifica nelle note)': 'Other (specify in notes)'}}</option>
                        </select>
                        <span class="label-form">{{ currLang === 'it' ? 'Tipologia Azienda': 'Company Type'}}</span>
                    </div>

                </div>

                <div class="flex flex-wrap mx-3 px md:my-6">
                    <div class="w-full md:w-1/2  my-2 md:my-0 relative">
                        <input type="text" v-model="AgentName" :class="{ 'input-form w-95': true, 'unempty': AgentName }" />
                        <span class="label-form">{{ currLang === 'it' ? 'Referente': 'Reference'}}</span>
                    </div>
                    <div class="w-full md:w-1/2  my-2 md:my-0 relative">
                        <input type="text" v-model="Address" :class="{ 'input-form w-95': true, 'unempty': Address }" />
                        <span class="label-form">{{ currLang === 'it' ? 'Indirizzo': 'Address'}}</span>
                    </div>
                </div>

                <div class="flex flex-wrap mx-3 px md:my-6">

                    <div class="w-full md:w-1/2  my-2 md:my-0 relative">
                        <input type="text" v-model="City" :class="{ 'input-form w-95': true, 'unempty': City }" />
                        <span class="label-form">{{ currLang === 'it' ? 'Città': 'City'}}</span>
                    </div>
                    <div class="w-full md:w-1/2  my-2 md:my-0 relative">
                        <input type="text" v-model="Province" :class="{ 'input-form': true, 'unempty': Province }" />
                        <span class="label-form">{{ currLang === 'it' ? 'Provincia': 'Province'}}</span>
                    </div>

                </div>

                <div class="flex flex-wrap mx-3 px md:my-6">
                    <div class="w-full  my-2 md:my-0 relative">
                        <input type="text" v-model="VatCode" :class="{ 'input-form ': true, 'unempty': VatCode }"
                            required />
                        <span class="label-form">{{ currLang === 'it' ? 'Partita IVA': 'VAT Code'}} *</span>
                        
                    </div>
                </div>

                <div class="flex flex-wrap mx-3 px md:my-6">
                    <div class="w-full md:w-1/2 my-2 md:my-0  relative">
                        <input type="email" v-model="Email"
                            :class="{ 'input-form w-95': true, 'unempty': Email, 'mailError': ErrorMail }" required />
                        <span class="label-form">Email *</span>
                        <span v-if="ErrorMail" class="errorMessage">
                            {{ currLang === 'it' ? 'Questa email è già registrata, provane un\'altra.': 'This email is already registered.'}}. </span>
                    </div>
                    <div class="w-full md:w-1/2 my-2 md:my-0 relative">
                        <input type="tel" v-model="Phone" :class="{ 'input-form w-95': true, 'unempty': Phone }" required />
                        <span class="label-form">{{ currLang === 'it' ? 'Telefono': 'Phone'}} *</span>
                    </div>

                </div>

                <div class="flex flex-wrap mx-3 px mb-6">
                    <div class="w-full my-2 md:my-0  relative">
                        <textarea v-model="Notes" class="h-24" :class="{ 'input-form': true, 'unempty': Notes }"></textarea>
                        <span class="label-form">{{ currLang === 'it' ? 'Note': 'Notes'}}</span>
                    </div>
                </div>

                <div class="flex flex-wrap mx-3 px mb-6">

                    <input id="privacy" type="checkbox" v-model="privacy" class="mr-2" required>
                    <label for="privacy" class="form-checkbox-label">{{ currLang === 'it' ? 'Ho preso visione della ': 'I have read '}}<a
                            href="https://www.ptson.com/PBCPPlayer.asp?ID=1167665" target="_blank">Privacy Policy *</a></label>
                </div>

                <div class="flex justify-center">
                    <button type="submit" class="button-form bg-gray-900 text-white mt-5 py-2 rounded-md"
                        :class="{ 'loading': btnLoading }"> <span class="loader"></span>{{ currLang === 'it' ? 'Invia': 'Send'}}</button>
                </div>
            </form>
        </div>

        <a href="https://www.idexaweb.com/">
            <div class="mx-auto max-w-2xl px-6 py-6">
                <div class="credits-container">
                    <div class="credits-row">
                        <span> Credits</span>
                        <img class="idexa-logo" alt="PTS logo" src="../assets/logo-idexaweb.webp">
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            CompanyName: '',
            CompanyType: '',
            AgentName: '',
            Address: '',
            City: '',
            Province: '',
            VatCode: '',
            Email: '',
            Phone: '',
            Notes: '',
            // Altri campi...
            privacy: false,
            formSuccess: false,
            formError: false,
            errorMessage: '',
            ErrorMail: '',
            btnLoading: false,
            currLang: 'it'

        };
    },
    methods: {
        async submitForm() {
            this.btnLoading = true;
            const data = {
                CompanyName: this.CompanyName,
                CompanyType: this.CompanyType,
                AgentName: this.AgentName,
                Address: this.Address,
                City: this.City,
                Province: this.Province,
                VatCode: this.VatCode,
                Email: this.Email,
                Phone: this.Phone,
                Notes: this.Notes,
                // Altri campi...
                Privacy: this.privacy,
            };


            await fetch('https://b2b.ptson.com/api/index.php/wp-json/iarf/v1/consumer_add/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);

                    if (data.success) {
                        this.btnLoading = false;
                        this.formSuccess = true;
                        console.log(data)
                    }

                    if (data.error === 'x51') {
                        console.log(true)
                        this.ErrorMail = true;
                    }

                })
                .catch(error => {
                    console.error('Errore:', error);
                    this.formError = true;
                    this.errorMessage = error;
                    this.btnLoading = false;
                    // Gestire l'errore qui, es. mostrare un messaggio di errore
                });

        },
        autoFill() {
            const companyNames = ['Azienda1', 'Azienda2', 'Azienda3', 'Azienda4', 'Azienda5'];
            const companyTypes = ['Ingrosso', 'Negozio', 'eCommerce', 'Lunapark', 'Sala Bingo'];
            const agentNames = ['Mario', 'Luigi', 'Giovanni', 'Francesco', 'Antonio'];
            const cities = ['Roma', 'Milano', 'Napoli', 'Torino', 'Firenze'];
            const provinces = ['RM', 'MI', 'NA', 'TO', 'FI'];

            this.CompanyName = `${this.getRandomElement(companyNames)} ${Math.random().toString(36).substring(2, 5).toUpperCase()}`;
            this.CompanyType = this.getRandomElement(companyTypes);
            this.AgentName = this.getRandomElement(agentNames);
            this.Address = 'Via Roma 1, 00100 Roma';
            this.City = this.getRandomElement(cities);
            this.Province = this.getRandomElement(provinces);
            this.VatCode = Math.random().toString(36).substring(2, 12);
            this.Email = `${Math.random().toString(36).substring(2, 12)}@idexaweb.com`;
            this.Phone = this.getRandomNumber(10);
            this.Notes = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...'; // 200 caratteri
            this.IpAddress =
                this.privacy = true;
        },
        getRandomElement(array) {
            return array[Math.floor(Math.random() * array.length)];
        },
        getRandomNumber(length) {
            let result = '';
            for (let i = 0; i < length; i++) {
                result += Math.floor(Math.random() * 10); // genera un numero da 0 a 9
            }
            return result;
        }, 
        toggleLanguage() {
            this.currLang = this.currLang === 'it' ? 'en' : 'it';
        },
        getButtonLabel() {
        return this.currLang === 'it'
            ? ' <img src="../assets/it.png" width="48"> ENGLISH' // Usa entità HTML per <
            : '&lt; <img src="../assets/it.png" width="48"> ITALIANO';
    },
    },
    mounted() {
       // this.autoFill();
    }
};
</script>
  