<template>
    <header class="bg-white shadow-md mb-6">
    <div class="max-w-7xl p-4 flex justify-between items-center m-auto">
      <img class="h-10 main-logo mb-6" alt="PTS logo" src="../assets/logo.webp">
      <a href="#" @click="logout" class="text-blue-500 hover:text-blue-700">Logout</a>
      </div>
    </header>
  </template>


  <script>
export default {
    methods: {
        logout() {
          localStorage.removeItem('jwt');
        }
    

    },
};
</script>
  

